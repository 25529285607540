<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Detalle de orden
        </h3>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-slide-x-transition leave-absolute mode="in-out">
            <v-container class="px-6 pt-0" v-if="!loader">
              <v-row>
                <v-col cols="12" class="text-center">
                  <p class="text-h6 ma-0">
                    <span class="text--secondary text-subtitle-1">Orden</span>
                    {{ orderID }}
                  </p>
                  <p class="caption ma-0">
                    {{ product.created | toDateTransform }}
                  </p>
                  <v-chip class="ma-0" small>
                    <v-icon left size="20">
                      account_circle
                    </v-icon>
                    {{ product.sold_by.name }}
                  </v-chip>
                </v-col>
                <v-col cols="12" class="py-1">
                  <div class="d-flex justify-space-between">
                    <div>
                      <span class="caption">Total vendido</span>
                      <p class="ma-0 text-h6 ">
                        $
                        <span class="font-weight-bold mr-1">{{
                          product.total_sold
                        }}</span>
                        <span class="caption text--disabled">MXN</span>
                      </p>
                    </div>
                    <div class="text-right">
                      <span class="caption">Productos</span>
                      <p class="text-subtitle-1 ma-0 font-weight-bold">
                        {{ product.total_products | nameProductParts }}
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="py-1" v-if="product.discount">
                  <div class="d-flex justify-space-between">
                    <div>
                      <span class="caption">Subtotal</span>
                      <p class="text-subtitle-1 ma-0">
                        $ {{ product.subtotal_price }}
                      </p>
                    </div>
                    <div class="text-right">
                      <span class="caption">Descuento</span>
                      <p class="text-subtitle-1 ma-0">
                        $ {{ product.discount }}
                      </p>
                    </div>
                  </div>
                </v-col>
                <v-col cols="12" class="py-1">
                  <div class="d-flex justify-space-between">
                    <div>
                      <span class="caption">Metodo de pago</span>
                      <p class="text-subtitle-1 ma-0">
                        <span v-if="product.payment_method === 0" class="mr-2"
                          ><v-icon>payments</v-icon> Efectivo</span
                        >
                        <span v-else class="mr-2"
                          ><v-icon>credit_card</v-icon> Tarjeta</span
                        >
                      </p>
                    </div>
                    <div class="text-right">
                      <span class="caption">Descuento</span>
                      <p class="ma-0">$ {{ product.discount }}</p>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <p class="caption ma-0 mt-2">
                Lista de productos
                <span class="font-weight-bold"
                  >({{ product.product_list.length }})</span
                >
              </p>
              <template v-for="(product, index) in product.product_list">
                <v-card
                  :key="index + '-cart'"
                  height="85px"
                  class="no-select my-1"
                  outlined
                >
                  <v-list-item>
                    <v-list-item-content class="py-1">
                      <p class="caption ma-0">{{ product.id }}</p>
                      <v-list-item-title class="text-subtitle-1"
                        >{{ product.name_product }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-body-1 ma-0">
                        <span
                          v-if="product.wholesale"
                          v-html="
                            priceWithWholesale(
                              product.price_by_unit,
                              product.wholesale.wholesale_price
                            )
                          "
                        ></span>
                        <span v-else>{{
                          product.price_by_unit | priceWithoutWholesale
                        }}</span>
                      </v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="text--secondary text-body-2"
                        >{{
                          product.total_products | nameProductParts
                        }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-content class="text-right max-width-price">
                      <v-list-item-title class="mb-0"
                        >$ {{ product.total_price }}</v-list-item-title
                      >
                      <div
                        class="text-subtitle-2 text--disabled text-decoration-line-through"
                        v-if="product.subtotal_price > product.total_price"
                      >
                        $ {{ product.subtotal_price }}
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </template>
              <v-row>
                <v-col cols="12" v-if="product.edited" class="text-right">
                  <h3 class="text-center text-h6">Orden editada</h3>
                  <p class="caption mb-1 text-center text--disabled">
                    Esta orden fue edita el
                    {{ getLastUpdated(product.edited_metadata.last_update) }}
                  </p>

                  <div>
                    <span class="caption">Total de la orden anterior</span>
                    <p class="text-subtitle-1 ma-0">
                      $ {{ product.edited_metadata.last_total }}
                      <span class="caption text--disabled">MXN</span>
                    </p>
                  </div>
                  <div>
                    <span class="caption">Subtotal de la orden anterior</span>
                    <p class="text-subtitle-1 ma-0">
                      $ {{ product.edited_metadata.last_subtotal_price }}
                      <span class="caption text--disabled">MXN</span>
                    </p>
                  </div>
                  <div>
                    <span class="caption">Total de productos anteriores</span>
                    <p class="text-subtitle-1 ma-0">
                      {{
                        product.edited_metadata.last_total_products
                          | nameProductParts
                      }}
                    </p>
                  </div>
                  <div>
                    <span class="caption">Diferencia total de lo ventdido</span>
                    <p class="text-subtitle-1 ma-0">
                      {{ product.edited_metadata.variance }}
                      <span class="caption text--disabled">MXN</span>
                    </p>
                  </div>
                </v-col>
                <v-col cols="12" class="pb-0">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="d-flex justify-space-between">
                  <v-btn dark @click="printOrder"><v-icon>print</v-icon></v-btn>
                  <v-btn
                    color="primary"
                    :to="{ name: 'orderEdit', params: { orderID: orderID } }"
                    >Modificar orden</v-btn
                  >
                </v-col>
              </v-row>
            </v-container>
          </v-slide-x-transition>
          <v-container v-if="loader">
            <v-skeleton-loader
              type="list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, actions"
            ></v-skeleton-loader>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <app-print-ticket
      v-if="!loader"
      id="print-component"
      :orderID="orderID"
      :product="product"
      :business="businessData"
    ></app-print-ticket>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { database } from "@/config/firebase";
import { errorMessage, showMessage } from "@/services/MessageService";
import PrintTicket from "@/components/app/TemplateTicketPrint";
import moment from "moment";

export default {
  data() {
    return {
      product: null,
      loader: true,
      printOrderStatus: false
    };
  },
  props: {
    orderID: {
      required: true
    }
  },
  computed: {
    ...mapState("user", ["data", "userInfoData"]),
    ...mapState("business", ["currentBranchID", "businessData"])
  },
  mounted() {
    this.getOrderDetail();
    if (!this.businessData) this.getBusinessData();
  },
  methods: {
    ...mapActions("business", ["getBusinessData"]),
    async getOrderDetail() {
      this.loader = true;
      try {
        await database(`sales/${this.orderID}`).once("value", snapshot => {
          if (snapshot.exists()) {
            this.product = snapshot.val();
            this.loader = false;
          } else {
            this.$router.push({ name: "orders" });
            showMessage({
              message: `La orden con el id ${this.orderID} no fue encontrada por favor verificala.`,
              color: "warning",
              timeout: 4000
            });
          }
        });
      } catch (error) {
        errorMessage({
          message: "Error al obtener el detalle de la orden, code:" + error
        });
      }
    },
    printOrder() {
      try {
        // Get HTML to print from element
        const prtHtml = document.getElementById("print-component").innerHTML;
        // Open the print window
        const WinPrint = window.open(
          "",
          "",
          "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <body>
            ${prtHtml}
          </body>
        </html>`);
        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      } catch (error) {
        errorMessage({
          message: "Error al imprimir la orden, code:" + error
        });
      }
      this.printOrderStatus = false;
    },
    priceWithWholesale(price, wholesalePrice) {
      if (price > wholesalePrice) {
        return `$ ${wholesalePrice} <span class="text-subtitle-2 text--secondary text-decoration-line-through ml-1">$ ${price}</span>`;
      }
      return `$ ${price}`;
    },
    getLastUpdated(date) {
      if (date) {
        return moment.unix(date).format("dddd DD [a las] h:mm a [del] YYYY");
      }
      return "";
    }
  },
  filters: {
    nameProductParts(parts) {
      if (parts == 1) return parts + " Pieza";
      else return parts + " Piezas";
    },
    priceWithoutWholesale(price) {
      return `$ ${price}`;
    },
    toDateTransform(timeUnix) {
      if (timeUnix)
        return moment.unix(timeUnix).format("dddd DD [a las] h:mm [de] YYYY");
      return "No hay ventas recientes";
    }
  },
  components: {
    AppPrintTicket: PrintTicket
  }
};
</script>

<style>
.max-width-price {
  max-width: 70px !important;
}
</style>
