<template>
  <div id="print-component" :style="{ width: width }" style="display:none">
    <div style="text-align:center">
      <h2 style="font-family: 'Poppins', sans-serif !important;">
        Detalle de orden
      </h2>
    </div>
    <div
      style="display: flex;
      flex-wrap: wrap;
      margin: 15px 0"
    >
      <div style="flex: 1 1">
        <p
          style="margin: 0; font-family: 'Poppins', sans-serif !important; font-size:20px"
        >
          ORDEN <span style="font-weight: 700;">{{ orderID }}</span>
        </p>
        <p
          style="margin: 0; font-size: 0.75rem !important; font-weight: 400; font-family: 'Poppins', sans-serif !important;"
        >
          Fecha: {{ product.created | toDateTransform }}
        </p>
        <p
          style="margin: 0;font-family: 'Poppins', sans-serif !important; font-size:16px"
        >
          Atendido por: {{ product.sold_by.name }}
        </p>
      </div>
      <div style="flex: 1 1; text-align:right" v-if="business">
        <h3
          style="margin:0; font-family: 'Poppins', sans-serif !important; font-size:20px"
        >
          {{ business.name }}
        </h3>
        <p
          v-if="business.location"
          style="margin:0; font-family: 'Poppins', sans-serif !important; font-size:14px"
        >
          {{ business.location | locationFormat }}
        </p>
        <p
          v-if="business.phone"
          style="margin:0; font-family: 'Poppins', sans-serif !important; font-size:16px"
        >
          Tel: {{ business.phone }}
        </p>
        <p
          v-if="business.mail"
          style="margin:0; font-family: 'Poppins', sans-serif !important; font-size:16px"
        >
          {{ business.mail }}
        </p>
      </div>
    </div>
    <div>
      <div style="border: 1px solid #c5c5c5; border-radius: 5px;">
        <table style="width: 100%;">
          <colgroup>
            <col width="100%" />
            <col width="0%" />
            <col width="0%" />
            <col width="0%" />
          </colgroup>
          <tr
            style="height: 34px; -webkit-print-color-adjust: exact; background-color: #006cff; color: white;"
          >
            <th
              style="font-weight: 400; text-align:left; padding:0 10px; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              Producto
            </th>
            <th
              style="font-weight: 400; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              Piezas
            </th>
            <th
              style="font-weight: 400; text-align:right;  padding:0 10px; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              Precio c/u
            </th>
            <th
              style="font-weight: 400; text-align:right;  padding:0 10px; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              Total
            </th>
          </tr>
          <tr
            v-for="(productList, index) in product.product_list"
            :key="productList.id"
            :style="{ backgroundColor: index % 2 ? '#e6e6e6 !important' : '' }"
            style="-webkit-print-color-adjust: exact;"
          >
            <td
              style="height: 40px; padding:0 10px; white-space: nowrap; text-overflow:ellipsis; overflow: hidden; max-width:1px; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              {{ productList.name_product }}
            </td>
            <td
              style="height: 40px; white-space: nowrap; min-width:70px; text-align:center; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              {{ productList.total_products }}
            </td>
            <td
              style="height: 40px; padding:0 10px 0 0; white-space: nowrap;text-align: right; min-width:105px; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              <span
                v-if="productList.wholesale"
                v-html="
                  priceWithWholesale(
                    productList.price_by_unit,
                    productList.wholesale.wholesale_price
                  )
                "
              ></span>
              <span v-else>{{
                productList.price_by_unit | priceWithoutWholesale
              }}</span>
            </td>
            <td
              style="height: 40px; padding:0 10px; white-space: nowrap; min-width:65px; font-family: 'Poppins', sans-serif !important; font-size:16px"
            >
              <p style="margin:0; text-align: right">
                <span
                  v-if="productList.subtotal_price > productList.total_price"
                  style="font-size: 14px; text-decoration: line-through !important; margin-left: 5px;"
                  >$ {{ productList.subtotal_price }}</span
                >
                $ {{ productList.total_price }}
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div style="text-align: right; padding: 0 20px; margin-top: 20px;">
      <div>
        <div
          style="display: flex;
          justify-content: space-between;
          width: 250px;
          margin-left: auto;
          margin-right: 0;"
        >
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            Pago
          </p>
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            {{ product.payment_method === 0 ? "Efectivo" : "Tarjeta" }}
          </p>
        </div>
        <div
          style="display: flex;
          justify-content: space-between;
          width: 250px;
          margin-left: auto;
          margin-right: 0;"
        >
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            Total de piezas
          </p>
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            {{ product.total_products }}
          </p>
        </div>
        <div
          v-if="product.discount"
          style="display: flex;
          justify-content: space-between;
          width: 250px;
          margin-left: auto;
          margin-right: 0;"
        >
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            Descuento
          </p>
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            $ {{ product.discount }}
          </p>
        </div>
        <div
          style="display: flex;
          justify-content: space-between;
          width: 250px;
          margin-left: auto;
          margin-right: 0;"
        >
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            Subtotal
          </p>
          <p style="margin:0; font-family: 'Poppins', sans-serif !important;">
            $ {{ product.subtotal_price }}
          </p>
        </div>
      </div>
      <p
        style="margin:0; margin-top:20px; font-family: 'Poppins', sans-serif !important;"
      >
        Total
      </p>
      <h2
        style="margin:0; text-align: right; font-family: 'Poppins', sans-serif !important;"
      >
        $ {{ product.total_sold }}
      </h2>
    </div>
    <footer
      style="position: absolute;
      bottom: 0;
      width: 100%;
      height: 2.5rem;"
    >
      <p
        style="font-size: 12px; font-family: 'Poppins', sans-serif !important;"
      >
        Make with love by <span style="font-weight: 700;">SSENCIAL</span>
      </p>
    </footer>
    <template v-if="device === 'mobile'">
      <div
        style="border: 1px solid #c5c5c5; border-radius: 5px; margin-bottom: 4px;"
      >
        <div
          style="padding:2px 15px;-webkit-print-color-adjust: exact; background-color: #006cff; color: white;"
        >
          <div style="float:right; width:100px; height: 25px; ">
            <div style="display: flex; justify-content: space-between;">
              <p style="margin:0">|</p>
              <p
                style="margin:0; font-family: 'Poppins', sans-serif !important;"
              >
                Total
              </p>
            </div>
          </div>
          <div
            style="width: 100%; height: 25px; font-family: 'Poppins', sans-serif !important;"
          >
            Detalle del producto
          </div>
        </div>
      </div>
      <div style="border: 1px solid #c5c5c5; border-radius: 5px;">
        <template v-for="(productList, index) in product.product_list">
          <div
            :key="productList.id"
            style="padding:2px 15px;-webkit-print-color-adjust: exact; "
            :style="{ backgroundColor: index % 2 ? '#e6e6e6 !important' : '' }"
          >
            <div>
              <div
                style="float:right; width:100px; line-height: 40px; height: 40px;"
              >
                <p style="margin:0; text-align: right">
                  <span
                    v-if="productList.subtotal_price > productList.total_price"
                    style="font-size: 14px; text-decoration: line-through !important; margin-left: 5px;"
                    >$ {{ productList.subtotal_price }}</span
                  >
                  $ {{ productList.total_price }}
                </p>
              </div>
              <div style="width: 100%; height: 40px;">
                <p
                  style="margin:0;
                white-space: nowrap !important;
                overflow: hidden !important;
                overflow-x: hidden !important;
                overflow-y: hidden !important;
                text-overflow: ellipsis !important;"
                >
                  <span style="font-weight: 700;"
                    >{{ productList.total_products }} x
                  </span>
                  {{ productList.name_product }}
                </p>
                <p style="margin:0; font-weight: 400;">
                  <span
                    v-if="productList.wholesale"
                    v-html="
                      priceWithWholesale(
                        productList.price_by_unit,
                        productList.wholesale.wholesale_price
                      )
                    "
                  ></span>
                  <span v-else>{{
                    productList.price_by_unit | priceWithoutWholesale
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";

export default {
  data() {
    return {};
  },
  props: {
    orderID: {
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    business: {
      type: Object,
      default: null
    },
    width: {
      type: String,
      default: "100%"
    },
    device: {
      type: String,
      default: "desktop"
    }
  },
  methods: {
    priceWithWholesale(price, wholesalePrice) {
      if (price > wholesalePrice) {
        return `<span style="font-size: 14px; text-decoration: line-through !important; margin-left: 5px;">$ ${price}</span> $ ${wholesalePrice}`;
      }
      return `$ ${price}`;
    }
  },
  filters: {
    priceWithoutWholesale(price) {
      return `$ ${price}`;
    },
    toDateTransform(timeUnix) {
      if (timeUnix)
        return moment.unix(timeUnix).format("dddd DD [a las] h:mm [de] YYYY");
      return "No hay ventas recientes";
    },
    locationFormat(location) {
      return `${location.street_name}, ${location.city_name}, ${location.state_name}, México, ${location.postal_code}`;
    }
  }
};
</script>

<style>
.caption-print {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.25rem;
  font-family: "Poppins", sans-serif !important;
}
</style>
